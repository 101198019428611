<template>
  <v-card
    class="settings-card"
    elevation="3"
    rounded="lg"
    :loading="saving"
  >
    <template v-if="!get_printing_possible">
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon left color="primary">mdi-printer</v-icon>
        Print Server
      </v-card-title>
      <v-card-text>
        <v-alert
          type="info"
          class="mb-4"
          elevation="2"
          border="left"
          dense
        >
          Add Carrier accounts to be able to connect and {{ $appName }} Print Server. Print Server allows you to 
          automatically connect printers and label printers, to enable automatic 
          label printing without print dialogs when fulfilling orders.
        </v-alert>
      </v-card-text>
    </template>
    
    <template v-else>
      <v-card-title class="text-h5 font-weight-medium">
        <v-icon left color="primary">mdi-printer</v-icon>
        Print Server
      </v-card-title>
      
      <v-card-text>
        <p class="text-body-1 mb-4">Open {{ $appName }} Printserver App on the computer connected to the printers and paste the address it's listening to below. Make sure the computer is on and not sleeping.</p>
        
        <v-text-field
          outlined
          rounded
          label="Connection key"
          :value="`${company.id}-${company.printserver_key}`"
          append-outer-icon="mdi-content-copy"
          @click:append-outer="copytext(`${company.id}-${company.printserver_key}`)"
          style="max-width: 500px;"
          readonly
          class="mb-4"
        />
      </v-card-text>
      
      <v-card-actions class="d-flex flex-column align-start px-4 pb-4">
        <v-card-subtitle class="pl-0 pb-2">
          <span class="text-subtitle-1 font-weight-medium">Download {{ $appName }} Printserver App</span>
        </v-card-subtitle>
        
        <div v-if="allDownloads.arm64 && allDownloads.x64" class="w-100">
          <v-card outlined class="pa-4 download-options-card" rounded="lg">
            <v-card-subtitle class="pb-0 pl-0">Choose your Mac processor type:</v-card-subtitle>
            
            <v-card-text class="text-caption pl-0">
              Select the version that matches your Mac's processor architecture.
            </v-card-text>
            
            <v-card-actions class="pl-0">
              <v-btn
                :href="allDownloads.arm64"
                color="primary"
                rounded
                elevation="1"
                class="mr-2"
              >
                <v-icon left>mdi-apple</v-icon>
                Apple Silicon (M1/M2/M3)
              </v-btn>
              
              <v-btn
                :href="allDownloads.x64"
                color="secondary"
                rounded
                elevation="1"
              >
                <v-icon left>mdi-apple</v-icon>
                Intel Mac
              </v-btn>
            </v-card-actions>
            
            <v-card-text class="text-caption pt-2 pl-0">
              <strong>Not sure which to choose?</strong><br>
              • Apple Silicon: For newer Macs with M1, M2, or M3 chips (2020 and later)<br>
              • Intel: For older Macs with Intel processors
            </v-card-text>
          </v-card>
        </div>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapMultiRowFields, mapFields } from 'vuex-map-fields';
import firebase from 'firebase/app'
import 'firebase/storage'

export default {
  computed: {
    ...mapState({
      company: state => state.warehouse.company,
      config: state => state.app.config,
    }),
    ...mapFields(['warehouse.company.print_server_address']),
    ...mapMultiRowFields({
      p_map: 'warehouse.company.printer_map'
    }),
    ...mapGetters({
      get_printing_possible: 'warehouse/get_printing_possible',
      get_available_print_formats: 'app/get_available_print_formats'
    })
  },
  data() {
    return {
      loading: false,
      loading_connection: false,
      saving: false,
      errormsg: '',
      disabled_submit: true,
      showAdvancedDownloads: false,
      target_types: [
        {name: 'Shipping Labels', id: 'labels'},
      ],
      allDownloads: {
        arm64: '',
        x64: ''
      }
    }
  },
  methods: {
    copytext(text){
      this.$store.commit('app/SET_SNACK_BAR', 'Copied')
      navigator.clipboard.writeText(text)
    },
    trim(){
      this.print_server_address = this.print_server_address.replace(/ /gi, '')
    },
    async disconnect_server(){
      await this.$store.dispatch('warehouse/disconnect_printer')
    },
    async connectServer(){
      this.loading_connection = true
      if(!this.company.print_server_connected) this.errormsg = `Could not connect on address ${this.print_server_address}`
      this.loading_connection = false
    },
    removePrinter(index){
      this.company.printer_map.splice(index, 1)
      this.$store.commit('warehouse/SET_COMPANY', {printer_map: this.company.printer_map})
      this.savePrinterMap()
      this.disabled_submit = false
    },
    addPrinter(){
      this.$store.commit('warehouse/SET_COMPANY', {printer_map: this.company.printer_map.concat([{
          target: 'labels',
          printer_id: '',
          format: ''
        }])})
        this.savePrinterMap()
        this.disabled_submit = false
    },
    async savePrinterMap(){
      this.saving = true
      await this.$store.dispatch('warehouse/save_printers')
      this.disabled_submit = true
      this.saving = false
    },
    parseYmlForPath(ymlText) {
      // Extract the path from the YML file
      // Example YML format:
      // path: Fulfillment-Hero-1.0.0-arm64-mac.zip
      const pathMatch = ymlText.match(/path:\s*(.*-mac\.zip)/i)
      return pathMatch ? pathMatch[1] : null
    }
  },
  async mounted(){
    try {
      // Fetch the latest-mac.yml file for ARM64 (Apple Silicon)
      const arm64YmlUrl = await firebase.storage().ref().child('updates/latest-mac.yml').getDownloadURL()
      const arm64YmlResponse = await fetch(arm64YmlUrl)
      const arm64YmlText = await arm64YmlResponse.text()
      
      // Fetch the latest-mac-x64.yml file for Intel Macs
      const x64YmlUrl = await firebase.storage().ref().child('updates/latest-mac-x64.yml').getDownloadURL()
      const x64YmlResponse = await fetch(x64YmlUrl)
      const x64YmlText = await x64YmlResponse.text()
      
      // Parse the YML files to get the download URLs
      const arm64Path = this.parseYmlForPath(arm64YmlText)
      const x64Path = this.parseYmlForPath(x64YmlText)
      
      // Get the download URLs for both architectures
      const arm64DownloadUrl = await firebase.storage().ref().child(`updates/${arm64Path}`).getDownloadURL()
      const x64DownloadUrl = await firebase.storage().ref().child(`updates/${x64Path}`).getDownloadURL()
      
      // Store both URLs for users to choose from
      this.allDownloads = {
        arm64: arm64DownloadUrl,
        x64: x64DownloadUrl
      }
    } catch (error) {
      console.error('Error fetching latest app versions:', error)
    }
  }
}
</script>

<style lang="scss" scoped>
.settings-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 12px;
  overflow: hidden;
  animation: fadeIn 0.8s ease-out;
  margin-bottom: 24px;
  
  // &:hover {
  //   transform: translateY(-5px);
  //   box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
  // }
  
  .v-card__title {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding-bottom: 16px;
  }
  
  .v-card__text {
    padding-top: 16px;
  }
}

.download-options-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.08) !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
